<template>
  <v-card>
    <v-tabs v-model="selectedTab" show-arrows>
      <v-tab v-for="tab in tabs" :key="tab.title">
        <v-icon size="20" class="me-3">
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="selectedTab">
      <v-tab-item>
        <asset-detail
          :assetDetail="assetDetail"
          v-if="isDataLoaded"
        ></asset-detail>
      </v-tab-item>
      <v-tab-item>
        <asset-children
          :assetChildren="assetChildren"
          v-if="isDataLoaded"
        ></asset-children>
      </v-tab-item>
      <v-tab-item>
        <asset-files :assetFiles="assetFiles" v-if="isDataLoaded"></asset-files>
      </v-tab-item>
      <v-tab-item>
        <asset-timeseries
          :assetTimeseries="assetTimeseries"
          v-if="isDataLoaded"
        ></asset-timeseries>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { mdiPipe, mdiTextBoxSearch, mdiFileDocumentMultiple, mdiChartBellCurveCumulative } from "@mdi/js";
import AssetChildren from "./AssetChildren.vue";
import AssetDetail from "./AssetDetail.vue";
import AssetFiles from "./AssetFiles.vue";
import AssetTimeseries from "./AssetTimeseries.vue";

// TODO: Mapping between CDF and AWS timeseries data is going to be discussed in the future
// TODO: Error or Nodata Handling
export default {
  name: "DashboardAsset",
  components: {
    AssetChildren,
    AssetDetail,
    AssetFiles,
    AssetTimeseries,
  },
  data() {
    return {
      selectedTab: "",
      tabs: [
        { title: "Detail", icon: mdiTextBoxSearch },
        { title: "Child Assets", icon: mdiPipe },
        { title: "Files", icon: mdiFileDocumentMultiple },
        { title: "Timeseries", icon: mdiChartBellCurveCumulative },
      ],
      assetDetail: {},
      assetChildren: [],
      assetFiles: [],
      assetTimeseries: [],
      isDataLoaded: false,
    };
  },
  computed: mapGetters(["CDFClient"]),
  watch: {
    $route(to) {
      // Fetch new data regarding the selected asset
      const id = to.params.id;
      Promise.all([
        this.getDetail(id),
        this.getChildren(id),
        this.getFiles(id),
        this.getTimeseries(id),
      ]).then((result) => {
        this.assetDetail = result[0];
        this.assetChildren = result[1];
        this.assetFiles = result[2];
        this.assetTimeseries = result[3];
        this.isDataLoaded = true;
      });
    },
  },
  methods: {
    async getDetail(id) {
      const result = await this.CDFClient.assets.retrieve([{ id }]);
      return result[0];
    },
    async getChildren(id) {
      const result = await this.CDFClient.assets.list({
        filter: { assetSubtreeIds: [{ id }] },
      });
      return result.items;
    },
    async getFiles(id) {
      const result = await this.CDFClient.files.list({
        filter: { assetSubtreeIds: [{ id }] },
      });
      return result.items;
    },
    async getTimeseries(id) {
      const result = await this.CDFClient.timeseries.list({
        filter: { assetSubtreeIds: [{ id }] },
      });
      return result.items;
    },
  },
  mounted() {
    this.isDataLoaded = false;
    // Fetch new data regarding the selected asset
    const id = this.$route.params.id;
    Promise.all([
      this.getDetail(id),
      this.getChildren(id),
      this.getFiles(id),
      this.getTimeseries(id),
    ]).then((result) => {
      this.assetDetail = result[0];
      this.assetChildren = result[1];
      this.assetFiles = result[2];
      this.assetTimeseries = result[3];
      this.isDataLoaded = true;
    });
  },
};
</script>
