<template>
  <v-card height="380" class="scroll">
    <v-card-text>
      <v-list class="pb-0">
        <v-list-item-group v-model="selectedIndex">
          <v-list-item v-for="data in assetFiles" :key="data.name">
            <span>{{ data.name }}</span>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AssetFiles",
  props: ["assetFiles"],
  data() {
    return {
      selectedIndex: null,
      files: [],
    };
  },
  computed: mapGetters(["CDFClient"]),
  methods: {
    async getDonwloadURL(id) {
      // Fetch file donwload url and make it compatible with CDFclient.get()
      const urls = await this.CDFClient.files.getDownloadUrls([{ id }]);
      const url = urls[0]["downloadUrl"].replace(
        "https://api.cognitedata.com",
        ""
      );
      return url;
    },
    async getFileBinaryData(url) {
      const fileBinaryData = await this.CDFClient.get(url, {
        responseType: "arraybuffer",
      });
      return fileBinaryData;
    },
    getBlobURL(binaryData, contentType) {
      const blob = new Blob([binaryData], {
        type: contentType,
      });
      const blobURL = URL.createObjectURL(blob);
      return blobURL;
    },
    makeSVGLinksOnLoad(windowObj) {
      // Make all the g tags linked to its asset page.
      windowObj.onload = () => {
        const SVGElement = windowObj.document.querySelector("svg");
        const gMatches = SVGElement.querySelectorAll("g");
        for (let node of gMatches) {
          this.makeNodeLink(node, windowObj);
        }
      };
    },
    makeNodeLink(node, windowObj) {
      // Make a g tag hovable with pointer cursor
      // and, linked to its asset page.
      const tagName = node.querySelector("metadata id").innerHTML;
      node.style.cursor = "pointer";
      node.addEventListener("click", () => {
        this.CDFClient.assets
          .list({ filter: { name: tagName } })
          .then((result) => {
            const tagID = result.items[0]["id"];
            this.$router.push({ path: `/explorer/${tagID}` });
          });
        windowObj.close();
      });
    },
  },
  watch: {
    assetFiles: function (newFiles) {
      this.files = newFiles;
    },
    selectedIndex: async function () {
      const id = this.assetFiles[this.selectedIndex]["id"];
      const fileUrl = await this.getDonwloadURL(id);
      const fileBinaryData = await this.getFileBinaryData(fileUrl);
      const binaryData = fileBinaryData["data"];
      const contentType = fileBinaryData["headers"]["content-type"];
      const blobURL = this.getBlobURL(binaryData, contentType);
      // Make a new browser tab for the selected file to show.
      let newWindow = window.open(blobURL);
      if (contentType === "image/svg+xml") {
        // FIXME: SVG often cannot be rendered
        // due to the case newWindow is null
        this.makeSVGLinksOnLoad(newWindow);
      }
    },
  },
};
</script>
