<template>
  <v-card height="380" class="scroll">
    <v-card-title>{{ assetDetail.name }}</v-card-title>
    <v-card-subtitle>{{ assetDetail.description }}</v-card-subtitle>
    <v-card-text>
      <v-row>
        <v-col>
          <h4 class="font-weight-semibold">ID</h4>
          <span class="text-xs">{{ assetDetail.id }}</span>
        </v-col>
        <v-col>
          <h4 class="font-weight-semibold">External ID</h4>
          <span class="text-xs">{{ assetDetail.externalId }}</span>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider class="mx-4"></v-divider>
    <v-card-text>
      <v-row>
        <v-col>
          <h4 class="font-weight-semibold">Created at</h4>
          <span class="text-xs">{{ assetDetail.createdTime }}</span>
        </v-col>
        <v-col>
          <h4 class="font-weight-semibold">Updated at</h4>
          <span class="text-xs">{{ assetDetail.lastUpdatedTime }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "AssetDetail",
  props: ["assetDetail"],
  data() {
    return {
      name: "",
      description: "",
      id: null,
      externalId: "",
      createdTime: "",
      lastUpdatedTime: "",
    };
  },
  watch: {
    assetDetail: function (newDetail) {
      this.name = newDetail.name;
      this.description = newDetail.description;
      this.id = newDetail.id;
      this.externalId = newDetail.externalId;
      this.createdTime = newDetail.createdTime;
      this.lastUpdatedTime = newDetail.lastUpdatedTime;
    },
  },
};
</script>
