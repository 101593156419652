<template>
  <v-card height="380" class="scroll">
    <v-card-text>
      <v-list class="pb-0">
        <v-list-item-group v-model="selectedIndex">
          <v-list-item v-for="data in assetTimeseries" :key="data.name">
            <span>{{ data.name }}</span>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "AssetTimeseries",
  props: ["assetTimeseries"],
  data() {
    return {
      selectedIndex: null,
    };
  },
  methods: mapActions(["setSelectedTimeseriesID", "setSelectedTimeseriesName"]),
  watch: {
    selectedIndex: function () {
      // Put the timeseries name on selected by user.
      const name = this.assetTimeseries[this.selectedIndex]["name"];
      this.setSelectedTimeseriesName(name);
    },
  },
};
</script>
