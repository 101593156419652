<template>
  <v-card height="380" class="scroll">
    <v-data-table
      :headers="headers"
      :items="assetChildren"
      hide-default-footer
    ></v-data-table>
  </v-card>
</template>

<script>
export default {
  name: "AssetChildren",
  props: ["assetChildren"],
  watch: {
    assetChildren: function (newChildren) {
      this.assetList = newChildren;
    },
  },
  data() {
    return {
      headers: [
        { text: "Name", align: "start", sortable: false, value: "name" },
        { text: "Description", value: "description" },
        { text: "External ID", value: "externalId" },
      ],
    };
  },
};
</script>
